import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
export const query = graphql`
  {
    mdx(slug: { eq: "calorie-calculator-serving-size/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AppImage = makeShortcode("AppImage");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Even though calorie tracker
  apps have made our lives a lot easier, they can still be quite annoying. After
  a long day at work and a workout, you want to lie down on the couch and
  finally relax in front of the TV. But you forgot something, right the last
  meals still have to be tracked. Was it 50 grams or 60 grams of oatmeal after
  workout? Does this situation seem familiar to you? We're introducing another
  feature that will save you a lot of frustration by only requiring you to enter
  the number of servings of a food item to create your tracking entries.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Reliable Individual Serving Sizes`}</h2>
    <p>
  Many calorie trackers have stored serving data in their food search, sometimes
  several different ones. However, this is data that has been entered by other
  users. Basically, any and implausible data can be stored here. Also, this
  information cannot be checked manually due to its immense volume. This leads
  to the fact that many athletes and weight loss enthusiasts entrust their
  success partly to arbitrary data. And in fact, in many cases this is the
  reason why a diet fails. Is it therefore not obvious to give users the freedom
  to enter their own individual serving sizes for food? For us, the success of
  our users comes first, which is why we give you exactly this freedom.
    </p>
    <p>
  As you can see in the following image, you simply need to click on the
  "Serving size" button below the food summary to navigate to the input screen
  provided for this purpose.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  In the interface that follows, you simply enter the serving size of the food.
  You can also easily switch between the input in <b>grams and ounces</b>. After
  clicking on "Add entry", the portion size will be saved on your device and you
  will automatically navigate to the next input mask for the number of portions.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  If you have already used a food for meal entries, you will alternatively be
  shown an input mask like the one below. Here you can see your last used
  quantity. Either you can use it directly for further meal entries, or you can
  simply convert it to a serving size belonging to the food by clicking on
  "Serving size". Calorie tracking has never been easier!
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  Your portion size has been successfully assigned to the food. Now you only
  need to enter the number of servings to efficiently create meal entries in the
  future. From now on you don't need to remember the quantities of your food to
  create calorie tracker entries.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} mdxType="AppImage" />
    <h2>{`Be on the safe side!`}</h2>
    <p>
  Diets can be planned optimally and workout routines performed consistently.
  But if you end up tracking your diet incorrectly, your plan is doomed to
  failure. Therefore, do not rely on arbitrary and unverifiable portion
  information in food databases. The number of foods you eat is manageable,
  which is why you should store individual portion sizes once to track your diet
  easily, quickly and safely in the future. Achieve your goals and leave nothing
  to chance!
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      